<template>
  <div class="contactUs">
    <div class="content_area">
      <div class="title"><span>//</span>联系我们</div>
      <div class="main">
        <!--<div>-->
        <!--  GBK – 超过30年的行业经验, GBK公司的公司目标为中小企业提供全面合格的EH S 环境健康安全合规咨询服务。GBK公司成立于1986年，逐渐发展自己的全球咨询业务，与各国政府机构联系提供运输法规咨询服务。通过和多个专业机构和专家在多方面专业领域的战略合作，可以为客户提供一个全面专业的咨询网络，GBK同时在全球为超过750家企业提供咨询服务。-->
        <!--</div>-->
<!--        <div>-->
<!--          GBK公司必不可少的成功因素在提供可靠的充满竞争力的和富有经验的服务，同时十分重要的有是积极性和专业能力强的员工作为保障。GBK公司全球的员工和专家为您的项目提供单独咨询服务。-->
<!--GBK合规为君解难，提供多方面的咨询服务，包括随时为您解答各种合规问题，为您提供法规动态，为您的企业在法规丛林中提供合规的捷径。-->
<!--为您在黑暗中点亮明灯，是我们每天的使命和挑战。-->
<!--        </div>-->
        <div>
          有合作意向以及寻求危险品供应链服务，联系电话 021 62886580。
        </div>
      </div>
      <div class="add_form_box">
        <div class="adress">
          <mapPage :longitude="longitude" :dimension="dimension"></mapPage>
        </div>
        <div class="formbox">
          <div class="ticps">
            如果您在使用本网站时有任何意见或建议，请写下您的意见或者建议。我们会尽快答复您，感谢您的关注~
          </div>
          <div class="name_tel">
            <el-input
              size="small"
              class="input"
              v-model="evaluateVal.name"
              placeholder="名字"
              style="width: 180px"
            />
            <el-input
              size="small"
              class="input1"
              type="number"
              v-model="evaluateVal.phone"
              placeholder="电话"
              style="width: 242px"
            />
          </div>
          <div class="email">
            <el-input
              size="small"
              class="input1"
              v-model="evaluateVal.email"
              placeholder="邮箱"
            />
          </div>
          <div>
            <el-input
              type="textarea"
              class="textarea"
              placeholder="请写下您想说的话"
              v-model="evaluateVal.content"
            />
          </div>
          <div class="btn">
            <el-button
              size="mini"
              type="primary"
              class="submit"
              @click="submit"
              :loading="btnLoading"
              >提交</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validateTelephone, validateEmail } from "~/baseUtils/validate";
import mapPage from "~/baseComponents/map";
export default {
  components: { mapPage },
  metaInfo: {
    title: "联系我们",
  },
  data() {
    return {
      evaluateVal: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
      longitude: 121.440377,
      dimension: 31.244705,
      btnLoading: false,
      evaluateRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback("手机号格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
        email: {
          validator: async (rule, value, callback) => {
            if (!validateEmail(value)) {
              this.codeBtnDisabled = true;
              callback("邮箱格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    async submit() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (!this.evaluateVal.content) {
        this.$message.warning("输入建议不能为空");
        return;
      }
      this.btnLoading = true;
      this.evaluateVal.source = this.PJSource;
      let res = await this.$store.dispatch(
        "API_index/suggestionSave",
        this.evaluateVal
      );
      this.btnLoading = false;
      if (res.success) {
        this.$message.success("提交成功");
        this.evaluateVal = {};
      } else {
      }
    },
  },
  mounted() {
    console.log("this.PJSource", this.PJSource);
  },
};
</script>
<style lang="less" scoped>
.submit{
  background:#7ec9f1 ;
  border-color: #7ec9f1;
}
.contactUs {
  background: #ffffff;
  .content_area {
    padding: 30px 0 50px;
    .title {
      font-size: 24px;
      span {
        color: #e3954f;
        margin-right: 10px;
      }
    }
    .main {
      margin: 24px 0;
      div {
        text-indent: 2em;
        line-height: 25px;
      }
    }
    .add_form_box {
      display: flex;
    }
    .adress {
      height: 440px;
      width: 699px;
    }
    .formbox {
      background: #345292;
      width: 502px;
      padding: 36px 32px 28px 32px;
      .ticps {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
      .name_tel {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
      }
      .email {
        margin: 16px 0;
      }
      .btn {
        margin-top: 16px;
        text-align: center;
        button {
          width: 115px;
          height: 30px;
        }
      }
    }
  }

  /deep/.el-textarea__inner {
    min-height: 154px !important;
  }
  /deep/.el-form-item__error {
    color: #d8563a;
    margin-left: 16px;
  }
}
</style>